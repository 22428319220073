import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection pt-5 pb-5">
        <div className="container-fluid">
          <h3 className="subtitle">{t("OUR STORY")}</h3>
          <p className="">{data?.about_company}</p>
          <p>{t("about-1")}</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis libero praesentium commodi minus earum iste dolorem veritatis quam eveniet! Dicta qui eius atque amet voluptatibus optio tempore totam temporibus deserunt! Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam illum voluptatem blanditiis itaque tempore? Odio hic perspiciatis unde animi architecto minima earum temporibus fuga, quod aut at recusandae modi eaque!</p>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
