import React from "react";
import img1 from "../../../assets/img/banner-1.png";
import img2 from "../../../assets/img/banner-2.png";
import img3 from "../../../assets/img/banner-3.png";
import { Link } from "react-router-dom";
const data = [
  {
    id: "1",
    title: "Everyday Fresh & Clean with Our Products",
    url: img1,
  },
  {
    id: "2",
    title: "Make your Breakfast Healthy and Easy",
    url: img2,
  },
  {
    id: "3",
    title: "The best Organic Product Online",
    url: img3,
  },
];
const NotifyItem = () => {
  return (
    <>
      <section className="notifyItems">
        <div className="container-fluid">
          <div className="row">
            {data.map((item) => {
              return (
                <div className="col-md-4" key={item.id}>
                  <div className="notifyCard">
                    <img
                      src={item.url}
                      alt={item.title}
                      className="img-fluid"
                    />
                    <div className="notifyCardText">
                      <h3>{item.title}</h3>
                      <Link to="/">Shop Now </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default NotifyItem;
