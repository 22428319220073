import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import slider1 from "../../../assets/img/banner/slider-1-min.png";
import slider2 from "../../../assets/img/banner/slider-2-min.png";
import slider3 from "../../../assets/img/banner/slider-3-min.png";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection d-none">
        <div className="container-fluid">
          <Slider {...settings}>
            {data &&
              data.map((item) => {
                return (
                  item?.image?.url && (
                    <Link to={item?.url || ""} key={item._id}>
                      <div
                        className="bannerItem"
                        // style={!isMobile ? { height: "500px" } : {}}
                      >
                        <img
                          src={item?.image.url}
                          // className="aspect-ratio-img"
                        />
                      </div>
                    </Link>
                  )
                );
              })}
          </Slider>
        </div>
      </section>

      <section className="bannerSection">
          <Slider {...settings}>
            <div className="bannerItem">
              <Link to={"/"}>
                <img
                  className="img-fluid"
                  src={slider1}
                  alt="AL-Saniya Food Stuff Trading LLC"
                />
                <div className="bannerText">
                  <h2>Don’t miss amazing grocery deals</h2>
                  <p>Sign up for the daily newsletter</p>
                </div>
              </Link>
            </div>
            <div className="bannerItem">
              <Link to={"/"}>
                <img
                  className="img-fluid"
                  src={slider2}
                  alt="AL-Saniya Food Stuff Trading LLC"
                />
                <div className="bannerText">
                  <h2>Fresh Vegetables Big Discount</h2>
                  <p>Save up to 50% off on your first order</p>
                </div>
              </Link>
            </div>
            <div className="bannerItem">
              <Link to={"/"}>
                <img
                  className="img-fluid"
                  src={slider3}
                  alt="AL-Saniya Food Stuff Trading LLC"
                />
                <div className="bannerText">
                  <h2>Don’t miss amazing grocery deals</h2>
                  <p>Sign up for the daily newsletter</p>
                </div>
              </Link>
            </div>
          </Slider>
      </section>
    </>
  );
}

export default Banner;
