import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Blog from "../../components/blog/Blog";

function BlogPage({ setShow }) {
  return (
    <>
      <Helmet>
        <title>Blog | AL-Saniya Food Stuff Trading LLC</title>
        <meta name="keyword" content="AL-Saniya Food Stuff Trading LLC" />
        <meta name="description" content="AL-Saniya Food Stuff Trading LLC" />
      </Helmet>
      <Blog />
    </>
  );
}

export default BlogPage;
