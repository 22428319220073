import React from "react";
import CountDown from "./countdown/CountDown";
import BestDealsProducts from "./best-deals-products/BestDealsProducts";

const BestDeals = () => {
  return (
    <>
      <section className="bestDeals notifyItems">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3">
              <CountDown />
            </div>
            <div className="col-md-9">
              
              <BestDealsProducts />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BestDeals;
