import React from "react";
import Rating from "../../../shared/rating/Rating";
import user2 from "../../../assets/img/user-2.webp";

const Testimonial = () => {
  return (
    <>
      <section className="testimonial">
        <div className="container">
          <div className="testTitle">
            <h6>Customer Reviews</h6>
            <h2>Our Happy Customers</h2>
          </div>
          <div className="row ">
            <div className="col-md-3">
              <div className="testFigure">
                <img src={user2} alt="Theodore Handle" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="testText">
                <Rating />
                <p>
                  I have been shopping from shofi for the past few months and am
                  loving the experience. Have been shopping from here have
                  recommended my relatives too.
                </p>
                <h5 className="user">Theodore Handle</h5>
                <span className="profile">UI/UX Design</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
